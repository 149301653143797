import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Icon, Button } from '@hagerty/react-components';

import { login } from 'src/components/AuthProvider/browser';
import { isReady } from 'src/components/SplitContext/isReady';
import { usePurchaseFlowOptimization } from 'src/hooks/usePurchaseFlowOptimization';
import usePreventMemberCheckout from 'src/hooks/usePreventMemberCheckout';
import { SimpleFooter } from 'src/features/footer';

function PaymentLoginPage() {
  const splitIsReady = isReady();
  const { hasNewPurchaseFlow } = usePurchaseFlowOptimization();

  usePreventMemberCheckout();

  useEffect(() => {
    // Redirect user without feature_purchase_flow_optimization flag
    if (splitIsReady && !hasNewPurchaseFlow) {
      navigate('/checkout/information');
    }
  }, [splitIsReady, hasNewPurchaseFlow]);

  const handleLogin = () => {
    login({ state: { targetUrl: '/benefits?show_modal=true' } });
  };

  return (
    <div className="payment-confirmation color-background-light-gray">
      <div className="container container_center">
        <div className="payment-confirmation__container text-align-center">
          <div className="payment-confirmation__svg-shadow">
            <Icon icon="CheckCircle16" size="xxlarge" />
          </div>
          <div className="inset-m" />
          <h1 className="text-heading_2 no-padding">Payment Complete</h1>
          <div className="inset-m" />
          <p className="color-dark-1 no-padding">Log into your existing account to access your benefits</p>
          <div className="inset-m" />
          <Button buttonSize="Large" onClick={handleLogin}>
            Log in
          </Button>
        </div>
      </div>
      <SimpleFooter />
    </div>
  );
}

export default PaymentLoginPage;
